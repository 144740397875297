import { createSlice, PayloadAction } from "@reduxjs/toolkit"


interface DocumentValidationSliceState {
     firstNameInDocument:string | null
     lastNameInDocument:string | null
     disableFirstName:boolean
     disableLastName:boolean
}

const initialState : DocumentValidationSliceState = {
firstNameInDocument: "",
lastNameInDocument:"",
disableFirstName:false,
disableLastName:false

}

export const documentValidationSlice = createSlice(
{  name:'documentValidation',
   initialState,
   reducers:{
        setDocumentValidationError:  (state, action: PayloadAction<Partial<DocumentValidationSliceState>>) => {
          
            Object.assign(state, action.payload);
          },
          
   }
}

)

export default documentValidationSlice.reducer

export const {setDocumentValidationError} = documentValidationSlice.actions;