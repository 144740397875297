import { RefObject, useEffect, useRef, useState } from "react";
import PopUp from "../pop-up/PopUp.tsx";
import TermCard from "./TermCard.tsx";
import TermContentFullScreen from "./TermContentFullScreen.tsx";

interface Term {
  contents: string;
  assertion: string;
}

interface TermsComponentProps {
  terms: Term[];
  checkedStates: any;
  onCheckboxChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const TermsComponent = ({
  terms,
  checkedStates,
  onCheckboxChange,
}: TermsComponentProps) => {
  const thirdTerm = useRef<HTMLDivElement>(null);
  const firthTerm = useRef<HTMLDivElement>(null);
  const [fullScreenTerms, setFullScreenTerms] = useState<Term | null>(null);
  const showFullScreenTerms = (term: Term) => {
    setFullScreenTerms(term);
  };

  const closeFullScreenTerms = () => {
    setFullScreenTerms(null);
  };
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Check if device is mobile by screen width (example threshold: 1023px)
    const isMobile = window.innerWidth <= 1023;

    if (!isMobile && checkedStates) {
      if (checkedStates[0] && checkedStates[1]) {
        scrollToSection(thirdTerm  as RefObject<HTMLDivElement>);
      }
      if (checkedStates[2] && checkedStates[3]) {
        scrollToSection(firthTerm as RefObject<HTMLDivElement>) ;
      }
    }
  }, [checkedStates]);

  return (
    <div>
      {fullScreenTerms && (
        <PopUp onClose={closeFullScreenTerms}>
          <div>
            {fullScreenTerms.contents && (
              <TermContentFullScreen term={fullScreenTerms} />
            )}
          </div>
        </PopUp>
      )}
      {/* <PopUp /> */}
      <div className={`grid gap-6  lg:grid-cols-2 `}>
        {terms
          .filter((term) => term.contents !== null)
          .map((term, index) => {
            return (
              <TermCard
              key={term.contents}
                ref={
                  index === 2 ? thirdTerm : index === 4 ? firthTerm : undefined
                }
                showFullScreenTerms={showFullScreenTerms}
                numOfTerms={terms.length}
                term={term}
                index={index}
                checkedStates={checkedStates}
                onCheckboxChange={onCheckboxChange}
              />
            );
          })}
      </div>
    </div>
  );
};
