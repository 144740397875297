import { DropDown } from "@simplecitizen/gdl-react-ui-components";
import React from "react";
import { FieldErrors, useController } from "react-hook-form";
import { useAppSelector } from "src/hooks/redux-hooks";


interface SelectInputProps {
  control: any;
  dataCollector: DataCollector;
  errors: FieldErrors<any>
  required?:boolean
  onSelect?: (value:Option)=> void
}

const SelectInput = ({ control, dataCollector,errors,required = true,onSelect }: SelectInputProps) => {
  const { field } = useController({
    name: dataCollector.key,
    control,
    rules: {
      required: !required ? false :  dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',
    } 
    
  });

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  return (
    <DropDown
    error_message={errors[dataCollector?.key]?.message}
      inputClassName=""
      className=""
      color={client?.branding?.primaryColor || "#0A71C7"}
      labelClassName="font-normal normal-case"
      options={dataCollector.options}
      onSelect={(e: Option) => {
        field.onChange(e.value);
       if(onSelect){
        onSelect(e)
       }
      }}
      label={dataCollector.label}
    />
  );
};

export default SelectInput;
