import React, { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { DocumentUpload } from "@simplecitizen/gdl-react-ui-components";
import { CameraFacingMode } from "@regulaforensics/vp-frontend-document-components";
import { base64ToFile } from "src/utils/utils";


type ErrorObject = {
  [key: string]: {
    en: string;
  };
};

export type FieldError = {
  fieldName: string;
  error: string;
};

// const MAX_UPLOAD_ATTEMPTS = parseInt(
//   process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "3",
// );

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5003/api/workright";

const DocumentUploadMobileHandoff = () => {
  const [searchParams] = useSearchParams();
  const [faceMisMatch,setFaceMisMatch] = useState(false)
  const startScreen = searchParams.get("startScreen");
  const juriCode = searchParams.get("juri_code")
  const facialComparison = searchParams.get("facialComparison");
  const documentName = searchParams.get("documentName");
  const color = searchParams.get("color");
  const firstName = searchParams.get("firstName")
  const lastName = searchParams.get("lastName")

  /**
   * Converts a string by removing "data.", dots, and hyphens, and converting to lowercase.
   * @param {string} str - The string to convert.
   * @returns {string} - The processed string in lowercase without "data.", dots, or hyphens.
   */
  function toLowerNoSeparators(str: string) {
    return str
      .replace(/^data[.-]/, "")
      .replace(/[.-]/g, "")
      .toLowerCase();
  }

  /**
   * Converts an object of errors to an array of FieldError objects.
   * @param {ErrorObject} errors - The error object to convert.
   * @returns {FieldError[]} - Array of FieldError objects.
   */
  function convertErrorsToFieldErrors(errors: ErrorObject): FieldError[] {
    return Object.keys(errors).map((key) => ({
      fieldName: toLowerNoSeparators(key),
      error: errors[key].en,
    }));
  }

  const { checkId, docId, sessionId } = useParams<{
    checkId: string;
    docId: string;
    sessionId: string;
  }>();

  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [startOnRegulaUploader, setStartOnRegulaUploader] = useState(true);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [forceSubmit, setForceSumit] = useState(false);
  const [isUplaoding,setIsUploading] = useState(false);
  const [storeDocumentButtonText,setStoreDocumentButtonText] = useState("Store Document")
  const [disableStoreButton,setDisableStoreButton] = useState(false)
  const [storeDocument,setstoreDocument] = useState(false)

  const handleFileChange = useCallback( async (files: File[]): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", "front");
  
      const config = {
   
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
  
      const { data } = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/images`,
        formData,
        config
      );
  

      return Promise.resolve(data);
 
    } catch (error) {
      return Promise.reject("An error occurred while uploading the file");
     
    } finally {
    }
  },[checkId,docId]);

  const verifyDocument = async ({ docFrontSideImage,selfieImage,  }: any) => {
 
    setIsUploading(true);
    setForceSumit(false);
    setServerErrors([]);
    setFieldErrors([]);
    setFaceMisMatch(false)
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage },            {
        format: "base64",
        key: "selfie",
        source: selfieImage,
      },],
      relationships: [
        {
          type: "mobile-handoff-session",
          id: sessionId,
        },
      ],
    };

    try {
      await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/Validate`,
        newDocument
      );
      setDocumentUploaded(true);
      return Promise.resolve("success");

    } catch (error: any) {
      const document = error?.response?.data;
      if(error.response){
        if(error?.response?.data?.errors){
          if(error?.response?.data?.errors["images.selfie"]){
            setFaceMisMatch(true)
          }
      
        }
      }
      if (document && document?.document) {
     
         if(juriCode?.trim().toLowerCase() !== 'gbr'){
          setForceSumit(true);
         }

      }
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );
    
        setFieldErrors(field_errors);
        return Promise.reject("error");
      }
      return Promise.reject(error.response.data.message || "An error occurred");


    } finally {
  
      if(fieldErrors.length === 0) {
        setIsUploading(false);
        setStartOnRegulaUploader(false);
      }

   
    }
  };

  const forceDocument = async ({ docFrontSideImage }: any) => {

    setServerErrors([]);
    setFieldErrors([]);
 
    try {
      const base64Image = `data:image/png;base64,${docFrontSideImage}`;
      const file = base64ToFile(
        base64Image,
        "forced-document.png",
        "image/png"
      );
      const imageObject = await handleFileChange([file]);
      //
      const newDocument = {
        images: [imageObject],
        status: "created",
        relationships: [
          {
            type: "mobile-handoff-session",
            id: sessionId,
          },
        ],
      };
    await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/Validate`,
        newDocument
      );
      setDocumentUploaded(true);
      return Promise.resolve("success");

    } catch (error: any) {
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );

        setFieldErrors(field_errors);
        return;
      }
      return;

  
    }
  };

  return (
    <div className="h-full w-full flex items-center">
      <DocumentUpload
          storeDocument={storeDocument}
          disableStoreButton={disableStoreButton}
          storeDocumentButtonText={storeDocumentButtonText}
      faceMisMatch={faceMisMatch}
        forceSubmit={forceSubmit}
        facialComparison={facialComparison === "true"}
        uploadAttempts={3}
        regulaStartScreen={startScreen === "true"}
        onForceRegulaDocument={async ({ docFrontSideImage }, rawDocument) => {
                    if(isUplaoding) return;

          const base64Data = docFrontSideImage.split(",")[1];
          // setUploadedImage(base64Data);
          await forceDocument({ docFrontSideImage: base64Data });
          setStartOnRegulaUploader(false);
          setDocumentUploaded(true);
        }}
        startOnRegulaUploader={startOnRegulaUploader}
        isDocumentUploadedOnMobile={documentUploaded}
        regula_settings={{
          regulaLogo: false,
          changeCameraButton: true,
          cameraMode: "environment" as CameraFacingMode,
          multipageProcessing: true,
          closeButton: false,
        }}
        fieldErrors={fieldErrors}
        onReplace={() => {}}
        verifyDocument={async ({ docFrontSideImage,selfieImage  }, rawData) => {
          const base64Data = docFrontSideImage.split(",")[1];
      
          await verifyDocument({ docFrontSideImage: base64Data,selfieImage });
        }}
        onStoreRegulaDocument={async ({ givenNames,   surname,docFrontSideImage,selfieImage }) => {
      
          if(isUplaoding) return;
          setDisableStoreButton(false)
          setFieldErrors([]);
         setstoreDocument(true)
          setStoreDocumentButtonText("Store Document")
          
          if(  givenNames.value.trim().toLowerCase() !== firstName?.trim().toLowerCase() &&  surname.value.trim().toLowerCase() !== lastName?.trim().toLowerCase()){
           setFieldErrors([
              {
                fieldName: 'givenNames',
                error: 'The First name does not match the one in your profile. Please update your first name on the desktop version to match the document, then scan the QR code again or upload a document that matches the name in your profile.',
              },
              {
                fieldName: 'Surname',
                error: 'The last name does not match the one in your profile. Please update your last name on the desktop to match the document, then scan the QR code again or upload a document that matches the name in your profile.',
              }
            ])
            setStoreDocumentButtonText("Store Document")
            setDisableStoreButton(true)
            setstoreDocument(false)
            return
          }
          if(  givenNames.value.trim().toLowerCase() !== firstName?.trim().toLowerCase()){
           setFieldErrors([
              {
                fieldName: 'givenNames',
                error: 'The First name does not match the one in your profile. Please update your first name on the desktop version to match the document, then scan the QR code again or upload a document that matches the name in your profile.',
              }
            ])
            setStoreDocumentButtonText("Store Document")
            setDisableStoreButton(true)
            setstoreDocument(false)
            return
          }
          if(  surname.value.trim().toLowerCase() !== lastName?.trim().toLowerCase()){
           setFieldErrors([
              {
                fieldName: 'Surname',
                error: 'The last name does not match the one in your profile. Please update your last name on the desktop to match the document, then scan the QR code again or upload a document that matches the name in your profile.',
              }
            ])
            setStoreDocumentButtonText("Store Document")
            setDisableStoreButton(true)
            setstoreDocument(false)
            return
          }
    
        }}
        verifyOnProcessingDocument={false}
        documentReaderProcessParams={{
          returnUncroppedImage: false,
        }}
        title={`Upload ${documentName}`}
        documentName={`${documentName}`}
        onRemoveDocument={() => {
      
        }}
  
        onStoreDefaultDocument={async (docData) => {
          console.log({ docData });

        }}
        serverErrors={serverErrors!}
        identityType={"identity"}
        color={
        color || "#14BDF3"
        }
      />
    </div>
  );
};

export default DocumentUploadMobileHandoff;
