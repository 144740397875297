import { useEffect, useMemo, useRef, useState } from "react";
import { Button, ConfirmDialog } from "@simplecitizen/gdl-react-ui-components";
import BioGraphical from "./BioGraphical";
import CitizenShip from "./CitizenShip";
import StatusCategory from "./StatusCategory";
import { useAppSelector } from "src/hooks/redux-hooks";
import StatusType from "./StatusType";
import RequiredDocuments from "./RequiredDocuments";
import { cn } from "src/utils/tailwindMerger";
import { Checkbox } from "../checkbox/Checkbox";
import ContentWrapper from "../content-wrapper/ContentWrapper";
import { useForm} from "react-hook-form";
import { submitCheck, submitVevo } from "src/services/apiService";
import Vevo from "./Vevo";
import RequiredData from "./RequiredData";
import ExperianQuestions from "./ExperianQuestions";
import { findStatusType } from "src/utils/utils";


export type Inputs = {
  firstName: string;
  middleName: string;
  lastName: string;
};

const QuestionnaireForm = ({
  scrollToNextPage,
  checkId,
}: {
  scrollToNextPage: () => void;
  checkId: string;
}) => {
  const {firstNameInDocument,lastNameInDocument} = useAppSelector(state => state.document_validation)
  const uploadedIdDocs = useAppSelector(
    (state) => state.identity_doc_capture.uploadedDocs
  );
  const [showDialog,setShowDialog] = useState(false)
  const firstNameRef =  useRef<HTMLDivElement>(null)
  const [experianFullAddress,setExperianFullAddress] = useState<any>(null)
  const uploadedNonIdDocs = useAppSelector((state) => state.non_id_docs.docs);
  const profile = useAppSelector((state) => state.profileData);
  const identityDocumentForced = useAppSelector((state) => state?.identity_doc_capture?.identityDocumentForced)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    trigger
  } = useForm<any>({
    mode: "onTouched",
    defaultValues: {
      firstName: profile.profile.name.firstName || "",
      middleName: profile.profile.name.middleName || "",
      lastName: profile.profile.name.lastName || "",
    },
  });

  const [selectedCitizenShip, setSelectedCitizenShip] = useState<
    "citizen" | "not_citizen" | null
  >(null);

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  const [selectedStatusCategoryId, setSelectedStatusCategoryId] = useState<
    string | null
  >(null);

  const [selectedStatusTypeId, setSelectedStatusTypeId] = useState<
    string | null
  >(null);

  const statusCategories = useAppSelector(
    (state) =>
      state.questionnaire_data.questionnaire?.jurisdiction.statusCategories
  );

  const [selectedCategory, setSelectedCategory] =
    useState<StatusCategory | null>(null);

    const selectedStatusCategories = useMemo(() => {
      if (!statusCategories) return []; 
      return  selectedCitizenShip === "citizen" 
          ? statusCategories.filter((category) => category.isCitizenship)
          : selectedCitizenShip === 'not_citizen'
          ? statusCategories.filter((category) => !category.isCitizenship) : null;
    }, [selectedCitizenShip, statusCategories]);

  const [selectedStatusType, setSelectedStatusType] =
    useState<StatusType | null>(null);

  const [vevoCheck, setVevoCheck] = useState({});
  useEffect(() => {
    if(selectedStatusCategories?.length === 1) {
      setSelectedStatusCategoryId(selectedStatusCategories[0]?._id)
    }
    else
    {
      setSelectedStatusCategoryId(null)
    }
  }, [selectedStatusCategories]);

  useEffect(() => {
    if (selectedStatusCategoryId) {
      const category = statusCategories?.find(
        (category) => category._id === selectedStatusCategoryId
      );
      if (category) {
        setSelectedCategory(category);
      }
    }
  }, [selectedStatusCategoryId, statusCategories]);

  useEffect(() => {
    if (selectedStatusCategoryId) {
      if (selectedStatusTypeId) {
        const statusType = statusCategories
          ?.find((category) => category._id === selectedStatusCategoryId)
          ?.statusTypes.find(
            (statusType) => statusType._id === selectedStatusTypeId
          );
        if (statusType) {
          setSelectedStatusType(statusType);
        }
      } else {
        setSelectedStatusType(null);
      }
    }
  }, [selectedStatusCategoryId, statusCategories, selectedStatusTypeId]);

  const resetStates = () => {
    setSelectedCategory(null);
    setSelectedStatusCategoryId(null);
    setSelectedStatusType(null);
    setSelectedStatusTypeId(null);
  };

  const citizenStategories = useMemo(() => {
    return statusCategories?.filter((category) => category.isCitizenship);
  }, [statusCategories]);

  const notCitizenCategories = useMemo(() => {
    return statusCategories?.filter((category) => !category.isCitizenship);
  }, [statusCategories]);

  useEffect(() => {
    if (citizenStategories?.length === 0) {
      setSelectedCitizenShip("not_citizen");
    }
    if (notCitizenCategories?.length === 0) {
      setSelectedCitizenShip("citizen");
    }
  }, [citizenStategories, notCitizenCategories]);

  const [showSubmit, setShowSubmit] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  const [documentCollectors, setDocumentCollectors] = useState<
    DocumentCollector[] | null
  >(selectedStatusType?.documentCollectors ?? null);
  const allUploadedDocs = useMemo(
    () => (uploadedIdDocs ?? []).concat(uploadedNonIdDocs),
    [uploadedIdDocs, uploadedNonIdDocs]
  );

  const uploadedRequiredDocs = useMemo(
    () => allUploadedDocs.filter((document: any) => document?.config?.required),
    [allUploadedDocs]
  );

  const requiredDocs = useMemo(() => {
    return selectedStatusType?.documentCollectors.filter(
      (document) => document.config.required
    );
  }, [selectedStatusType]);

  useEffect(() => {
    if (selectedStatusType) {
      setDocumentCollectors(selectedStatusType?.documentCollectors ?? null);
    }
  }, [selectedStatusType]);

  useEffect(() => {
    const allDocs = (uploadedIdDocs ?? []).concat(uploadedNonIdDocs);
    const uploadedRequiredDocs = allDocs.filter(
      (document: any) => document?.config?.required
    );
    const requiredDocs = selectedStatusType?.documentCollectors.filter(
      (document) => document.config.required
    );
    const isRequiredDocsUploaded =
      uploadedRequiredDocs.length >= (requiredDocs?.length ?? 0);
    if (selectedStatusType) {
      if (requiredDocs?.length === 0) {
        setShowSubmit(true);
        return;
      }
      if (requiredDocs && (isRequiredDocsUploaded || Object.keys(identityDocumentForced).length > 0)) {
        setShowSubmit(true);
        return;
      }
    }

    setShowSubmit(false);
  }, [selectedStatusType, requiredDocs, uploadedRequiredDocs, uploadedIdDocs, uploadedNonIdDocs, identityDocumentForced]);

  const [submitting, setSubmitting] = useState(false);
  const [docsError, setDocsError] = useState<string | null>(null);
  const [isVevoDone, setIsVevoDone] = useState(false);
  const [isVevoRequired, setIsVevoRequired] = useState(false);
  const [vevoApiError, setVevoApiError] = useState<string | null>(null);
  const [isSubmittingVevo, setIsSubmittingVevo] = useState(false);
  const questionnaire_data = useAppSelector(
    (state: any) => state?.questionnaire_data?.questionnaire
  );

  const selectVevoStatusType = (visaSubClass: string) => {
    const statusType = findStatusType(visaSubClass, selectedCategory?.statusTypes ?? []);


    if (statusType && visaSubClass) {
      setSelectedStatusType(statusType);
      setSelectedStatusTypeId(statusType._id);
   
    } else {
      setSelectedStatusType(selectedCategory?.statusTypes[0] ?? null)
      setSelectedStatusTypeId(selectedCategory?.statusTypes[0]._id ?? null)
    }
  };

  useEffect(() => {
    if (questionnaire_data) {
      if (
        questionnaire_data?.jurisdiction?.code2?.toUpperCase() !== "AU" &&
        questionnaire_data?.jurisdiction?.code3?.toUpperCase() !== "AUS" &&
        questionnaire_data?.jurisdiction?.name?.toUpperCase() !== "AUSTRALIA"
      ) {
        setIsVevoRequired(false);
        setIsVevoDone(true);
      }
    }
  }, [questionnaire_data]);

  useEffect(() => {
    if (questionnaire_data) {
      if (
        questionnaire_data?.jurisdiction?.code2?.toUpperCase() === "AU" ||
        questionnaire_data?.jurisdiction?.code3?.toUpperCase() === "AUS" ||
        questionnaire_data?.jurisdiction?.name?.toUpperCase() === "AUSTRALIA"
      ) {
        if (selectedCitizenShip === "not_citizen" && selectedCategory?.name.trim().toLowerCase() === 'Australian Visa Holder'.toLowerCase()) {
          setIsVevoRequired(true);
          setIsVevoDone(false);
        } else {
          setIsVevoRequired(false);
          setIsVevoDone(true);
        }
      }
    }
  }, [questionnaire_data, selectedCitizenShip,selectedCategory]);


  const onSubmitVevo = async (vevoData: VevoType) => {
    setIsSubmittingVevo(true);
    setVevoApiError(null);
    try {
      const data = await submitVevo(vevoData, checkId);
      setVevoCheck(vevoData);

      if (data?.visaSubclass) {
        const visaSubClass = data?.visaSubclass;
        selectVevoStatusType(visaSubClass);
      } else {
        throw new Error("No visa subclass found for the provided details");
      }
      setIsVevoDone(true);
    } catch (error: any) {
      if (error?.errors) {
        const errorMessages = Object?.values(error?.errors)?.flatMap(
          (errorDetail: any) => Object?.values(errorDetail)
        );
        if (errorMessages.length > 0) {
          return setVevoApiError(errorMessages.join(", "));
        }
      }

      setVevoApiError(
        "Please ensure that your full name is correct in the biographical information, and that your passport number, issue country, and date of birth are correct."
      );
    } finally {
      setIsSubmittingVevo(false);
    }
  };

  const onSubmit = async (data:Inputs,isConfirm = false) => {

    if(!isConfirm){
        const isFirstNameInTheDocument = firstNameInDocument?.trim() !== ""
        const isLastNameInTheDocument = lastNameInDocument?.trim() !== ""
        if(isFirstNameInTheDocument || isLastNameInTheDocument) {
          if(firstNameInDocument?.trim().toLowerCase() !== data.firstName.trim().toLowerCase() || lastNameInDocument?.trim().toLowerCase() !== data.lastName.trim().toLowerCase())
            {
              return setShowDialog(true)
            }
        }
   
    }
      
    const uploadedNonIdDocsIds = uploadedNonIdDocs
      ? uploadedNonIdDocs.map((doc: any) => ({
          [doc._id]: doc.doc_key,
        }))
      : [];

    const uploadedIdDocsIds = uploadedIdDocs
      ? uploadedIdDocs?.map((doc: any) => ({
          [doc._id]: doc.doc_key,
        }))
      : [];
    const allDocs = (uploadedIdDocs ?? []).concat(uploadedNonIdDocs);
    const uploadedRequiredDocs = allDocs.filter(
      (document: any) => document?.config?.required
    );
    const docsToSendArray = uploadedNonIdDocsIds.concat(uploadedIdDocsIds);
    const docsToSend = docsToSendArray?.reduce(
      (acc: { [x: string]: any }, doc: { [x: string]: any }) => {
        acc = { ...acc, ...doc };
        return acc;
      },
      {}
    );

    const requiredDocs = selectedStatusType?.documentCollectors.filter(
      (document) => document.config.required
    );
    const isRequiredDocsUploaded =
      uploadedRequiredDocs.length >= (requiredDocs?.length ?? 0);

    if (showSubmit) {
      setSubmitting(true);
      const fullData = selectedStatusType?.config?.experianAuthenticate ? {
        ...data,
        ...vevoCheck,
        experianFirstName: data.firstName,
        experianLastName: data.lastName,
        primaryResidentialAddress: experianFullAddress,
      } : { ...data, ...vevoCheck } 
      try {
        const check_data = {
          statusCategoryId: selectedCategory?._id,
          statusTypeId: selectedStatusType?._id,
          ProfileName: {
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
          },
          documents: {
            ...docsToSend,
          },
          data: fullData ,
        };
        if (!isRequiredDocsUploaded && (!isRequiredDocsUploaded && Object.keys(identityDocumentForced).length === 0)) {
          setDocsError("Please upload all required documents");
          return;
        }
        setDocsError("");
        await submitCheck(checkId, check_data);
        scrollToNextPage();
      } catch (error) {
        console.error("Error Submitting Form", error);
      } finally {
        setSubmitting(false);
      }
    }
  };
 const onConfirm = ()=> {
  setShowDialog(false)
  handleSubmit((data) => onSubmit(data, true))()

  }
  const closeDialog = ()=> {
    setShowDialog(false)
  }
  return (
    <>
      {showDialog && (
        <ConfirmDialog
          confirmButtonColor={client?.branding.primaryColor || "#0A71C7"}
          header="Name Mismatch Detected"
          message="There is a name mismatch which may effect the result of your RTW outcome, do you want to continue with submission without updating your profile?"
          confirmButtonText="Continue"
          cancelButtonText="Cancel"
          onConfirm={onConfirm}
          onCancel={closeDialog}
          onClose={closeDialog}
        />
      )}

    <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
      <ContentWrapper className="w-full pb-12 max-sm:px-4">
        <div ref={firstNameRef}>
        <BioGraphical control={control} errors={errors} />
        </div>
     
        {citizenStategories &&
          notCitizenCategories &&
          citizenStategories?.length > 0 &&
          notCitizenCategories?.length > 0 && (
            <CitizenShip
              resetStates={resetStates}
              setSelectedStatusType={setSelectedStatusType}
              setSelectedCategory={setSelectedCategory}
              selectedCitizenShip={selectedCitizenShip}
              setSelectedCitizenShip={setSelectedCitizenShip}
            />
          )}
          <>
            {selectedCitizenShip &&  (
              <StatusCategory
                resetStates={resetStates}
                setSelectedStatusType={setSelectedStatusType}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedCitizenShip={selectedCitizenShip}
                selectedStatusCategoryId={selectedStatusCategoryId}
                setSelectedStatusCategoryId={setSelectedStatusCategoryId}
              />
            )}

            {selectedCategory && isVevoDone && (
              <StatusType
                isVevoRequired={isVevoRequired}
                selectedStatusType={selectedStatusType}
                selectedCategory={selectedCategory}
                selectedStatusTypeId={selectedStatusTypeId}
                setSelectedStatusTypeId={setSelectedStatusTypeId}
              />
            )}
                {questionnaire_data &&
          selectedCitizenShip &&
          selectedCitizenShip === "not_citizen" &&
          isVevoRequired && (
            <Vevo
              isVevoDone={isVevoDone}
              vevoApiError={vevoApiError}
              onSubmitVevo={onSubmitVevo}
              isSubmittingVevo={isSubmittingVevo}
            />
          )}
            {selectedStatusType &&
              selectedStatusType?.dataCollectors.length > 0 && (
                <RequiredData
          
                  errors={errors}
                  control={control}
                  register={register}
                  selectedStatusType={selectedStatusType}
                />
              )}
            {selectedStatusType &&
              selectedStatusType?.config?.experianAuthenticate && (
                <ExperianQuestions setExperianFullAddress={setExperianFullAddress} errors={errors} register={register} control={control} />
              )}

            {selectedStatusType &&
              selectedStatusType?.documentCollectors.length > 0 && (
                <RequiredDocuments
                trigger={trigger}
                firstNameRef={firstNameRef}
                control={control}
                  documentCollectors={documentCollectors}
                  setDocumentCollectors={setDocumentCollectors}
                  selectedStatusType={selectedStatusType}
                />
              )}
          </>
      

        {docsError && (
          <div className="text-red-600 bg-red-400/40 text-left px-2 py-2 rounded mt-4">
            {docsError}
          </div>
        )}

        {(showSubmit ||
          (isVevoRequired && isVevoDone && !selectedStatusTypeId)) && (
          <>
            <div className="bg-gray-300 w-full h-[1px] my-8"></div>
            <div>
              <div
                style={{
                  boxShadow: "0px 0px 0px 0.2px rgb(0 0 0 / 0.2)",
                }}
                className="relative px-6 py-6 text-sm rounded-xl"
              >
                <div
                  style={{
                    backgroundColor: client?.branding.primaryColor || "#0A71C7",
                    boxShadow: "0px 0px 0px 0.2px rgb(0 0 0 / 0.2)",
                    borderColor: client?.branding.primaryColor || "#0A71C7",
                    borderWidth: "2px",
                    borderStyle: "solid",
                  }}
                  className={cn(
                    "opacity-[0.1] absolute top-0 right-0 h-full w-full rounded-xl "
                  )}
                ></div>
                <div>
                  The above information is true and accurate to the best of your
                  knowledge. Any misrepresentation to your work status may
                  result in a delay of confirming your right to work and if you
                  have not already started work, may result in a delay to
                  starting your role.
                </div>
                <div className="mt-4">
                  <Checkbox
                    checked={isTermsAgreed}
                    onChange={() => {
                      setIsTermsAgreed(!isTermsAgreed);
                    }}
                    label={"I agree to the above"}
                  />
                </div>
              </div>

              <Button
                disabled={!isTermsAgreed}
                color={client?.branding.primaryColor || "#0A71C7"}
                className="mt-8 w-full"
                type="submit"
              >
                {submitting ? "Submitting..." : "Submit Response"}
              </Button>
            </div>
          </>
        )}
      </ContentWrapper>
    </form>
    </>
  );
};

export default QuestionnaireForm;
