import React, { useEffect, useState } from "react";
import { cn } from "../../../utils/tailwindMerger";
import ContentWrapper from "../content-wrapper/ContentWrapper";

import Heading from "../heading/Heading";
import WhiteCard from "../white-card/WhiteCard";
import { TextInput, Button } from "@simplecitizen/gdl-react-ui-components";

import { useDispatch } from "react-redux";
import { getQuestionnaireProfile, requestOTP } from "src/services/apiService";
import { fetchProfile } from "src/store/slices/profileSlice";

interface PassCodeBoxProps {
  className?: string;
  backgroundColor?: string;
  isDisabled: boolean;
  beginCheck?: () => void;
  checkId: string;
  scrollToNextPage: () => void;
}

const PassCodeBox = ({
  className,
  backgroundColor,
  isDisabled,
  checkId,
  scrollToNextPage,
}: PassCodeBoxProps) => {
  const [passCode, setPassCode] = useState("");
  const [requestingOTP, setRequestingOTP] = useState(false);
  const [otpError, setOtpError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const handleRequestOTP = async () => {
    if (requestingOTP || countdown > 0) return;
    setRequestingOTP(true);
    try {
      await requestOTP(checkId, "check", dispatch);
      setCountdown(60); // Start the countdown
    } catch (error) {
      console.error("Failed to request OTP");
    } finally {
      setRequestingOTP(false);
    }
  };
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    setCountdown(60);
  }, []);

  const beginCheck = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOtpError(null);
    setSubmitting(true);
    getQuestionnaireProfile("check", checkId, passCode?.toUpperCase())
      .then((response) => {
        if (response) {
          dispatch(fetchProfile(response));

          scrollToNextPage();
        }
      })
      .catch((error) => {
       
        setOtpError(error.response?.data?.message || "Incorrect OTP");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div className={cn("relative ", className)}>
      <div
        style={{
          backgroundColor: backgroundColor || "#0A71C7",
          borderColor: backgroundColor || "#0A71C7",
          borderWidth: "2px",
          borderStyle: "solid",
        }}
        className={cn(
          "opacity-[0.1] absolute top-0 right-0 h-full w-full rounded-xl "
        )}
      ></div>
      <div
        style={{
          borderColor: backgroundColor || "#0A71C7",
          borderWidth: "1.4px",
          borderStyle: "solid",
        }}
        className={cn(
          "opacity-20 absolute top-0 right-0 h-full w-full rounded-xl z-6"
        )}
      ></div>
      <ContentWrapper className={cn("relative z-5 py-6 !grid lg:!grid-cols-3 md:!grid-cols-2 gap-x-2")} >
        <div className="lg:col-span-2 flex flex-col justify-between max-sm:mb-4">
          <div>
            {" "}
            <Heading size="sm">Pass Code</Heading>
            <div className="font-semibold mt-3">
              A one time pass code has been sent to your email address in order
              to confirm your identity be submitting your response.
            </div>
          </div>

          <div className="font-semibold  !hidden md:!block">
            <span className="">Didn’t receive a code?</span>{" "}
            <span
              role="button"
              onClick={handleRequestOTP}
              style={{
                color: backgroundColor || "#0A71C7",
              }}
              className={cn(
                "",
                requestingOTP || countdown > 0
                  ? "opacity-[0.4] pointer-events-none"
                  : "opacity-1"
              )}
            >
              {requestingOTP
                ? "Requesting..."
                : countdown > 0
                  ? `Request a new one in ${countdown} seconds`
                  : "Request a new one"}
            </span>
          </div>
        </div>
        <form onSubmit={beginCheck}>
          <WhiteCard>
            <div className="flex gap-x-1">
              <span className="text-red-500">*</span>{" "}
              <h3 className="font-semibold">One Time Pass Code</h3>
            </div>
            {otpError && <div className="text-red-500">{otpError}</div>}

            <div className="mt-3">
              <TextInput
                value={passCode}
                onChange={(e: any) => {
                  if (e.target.value.length > 6) return;
                  setPassCode(e.target.value.toUpperCase());
                }}
              />
            </div>
            <Button
              disabled={passCode.length < 6 || isDisabled}
              type="submit"
              color={backgroundColor || "#0A71C7"}
              className="bg-primary-blue w-full"
            >
              {submitting ? "Loading..." : "Begin Right to Work Check"}
            </Button>
          </WhiteCard>
          <div className="font-semibold space-x-2 md:!hidden !flex flex-col items-center  mt-4">
            <span className="">Didn’t receive a code?</span>{" "}
            <span
              role="button"
              onClick={handleRequestOTP}
              style={{
                color: backgroundColor || "#0A71C7",
              }}
              className=""
            >
              {requestingOTP
                ? "Requesting..."
                : countdown > 0
                  ? `Request a new one in ${countdown} seconds`
                  : "Request a new one"}
            </span>
          </div>
        </form>
      </ContentWrapper>
    </div>
  );
};

export default PassCodeBox;
