import { useRef, ReactNode, RefObject } from "react";
import useClickOutside from "src/hooks/useClickOutSide";
import { cn } from "../../../utils/tailwindMerger";
import { IoMdClose } from "react-icons/io";

interface PopUpProps {
  children: ReactNode;
  onClose: () => void;
  contentContainerClassName?: string;
  backdropClassName?: string;
}

/**
 * Represents a pop-up component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - The function to be called when the pop-up is closed.
 * @param {string} props.backdropClassName - The CSS class name for the backdrop element.
 * @param {string} props.contentContainerClassName - The CSS class name for the content container element.
 * @param {ReactNode} props.children - The content to be rendered inside the pop-up.
 * @returns {JSX.Element} The rendered pop-up component.
 */
const PopUp = ({
  onClose,
  backdropClassName,
  contentContainerClassName,
  children,
}: PopUpProps) => {
  const popContentRef = useRef<HTMLDivElement>(null);
  useClickOutside(popContentRef as RefObject<HTMLElement>, () => onClose());

  return (
    <div
      className={cn(
        "fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.7)] z-10 flex justify-center items-center",
        backdropClassName
      )}
    >
      <div
        onClick={onClose}
        role="button"
        className="fixed text-white top-5 right-5 cursor-pointer z-20 bg-[rgba(0,0,0,0.6)] rounded-full p-2"
      >
        <IoMdClose />
      </div>
      <div
        ref={popContentRef}
        className={cn(
          "bg-white md:max-w-[80%] max-w-[90%] p-12 rounded-lg md:max-h-[80%] max-h-[90%] overflow-auto relative",
          contentContainerClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PopUp;
