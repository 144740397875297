import get from "axios";
import axios from "axios";
import { setErrorData } from "../store/slices/errorSlice.ts";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5003/api/workright";

export const getQuestionnaire = async (id: string, dispatch?: any) => {
  const newApi = `/check/${id}/questionnaire`;

  try {
    const response = await get(`${API_BASE_URL}/v1${newApi}`);
    return response.data;
  } catch (error: any) {
    console.error("Error fetching questionnaire:", error.response);
    dispatch(
      setErrorData({
        status: error.response?.status,
        message: error.response?.data.message,
        endpoint: `${API_BASE_URL}/v1${newApi}`,
      })
    );
    throw error;
  }
};

export const requestOTP = async (
  id: string | undefined,
  check?: string,
  dispatch?: any
) => {
  const newApi = `/check/${id}/otp/request`;

  try {
    const response = await axios.post(`${API_BASE_URL}/v1${newApi}`);
    return response.data;
  } catch (error: any) {
    console.error("Error Sending OTP:", error);
    dispatch(
      setErrorData({
        status: error.response?.status,
        message: error.response?.data.message,
        endpoint: `${API_BASE_URL}/v1${newApi}`,
      })
    );
    throw error;
  }
};

export const getQuestionnaireProfile = async (
  check: string,
  id: string | undefined,
  xOtp: string,
  dispatch?: any
) => {
  const newApi = `/check/${id}/questionnaire/profile`;

  try {
    const response = await get(`${API_BASE_URL}/v1${newApi}/${xOtp}`, {});
    return response.data;
  } catch (error: any) {
    console.error("Error fetching questionnaire profile:", error);
    if(dispatch) {
      dispatch(
        setErrorData({
          status: error.response?.status,
          message: error.response?.data.message,
          endpoint: `${API_BASE_URL}/v1${newApi}`,
        })
      );
    }
    throw error;
  }
};

export const submitCheck = async (
  id: string | undefined,

  data: any
) => {
  const newApi = `/check/${id}/response`;

  try {
    const response = await axios.post(`${API_BASE_URL}/v1${newApi}`, data);
    return Promise.resolve(response.data);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const uploadNonIdentityDocument = async (
  id: string,
  checkId: string,
  data: any
) => {
  const newApi = `/checks/${checkId}/document-collectors/${id}/Validate`;

  try {
    const response = await axios.post(`${API_BASE_URL}/v1${newApi}`, data);
    return Promise.resolve(response.data);
  } catch (error: any) {
    const errorData = error.response.data;
    return Promise.reject(errorData);
  }
};

export const submitVevo = async (data: VevoType, checkId: string) => {
  const newApi = `/check/${checkId}/vevo`;

  try {
    const response = await axios.post(`${API_BASE_URL}/v1${newApi}`, data);
    return Promise.resolve(response.data);
  } catch (error: any) {
    const errorData = error.response.data;
    return Promise.reject(errorData);
  }
};
